import React, { useState } from 'react'
import { AppBar, Tabs, Tab, Box, makeStyles } from '@material-ui/core'
import ActivitiesList from '../ActivitiesList'
import CoursesTab from './CoursesTab'

const useStyles = makeStyles(theme => ({
  appBarRoot: {
    boxShadow: 'none',
    marginBottom: 20
  },
  tabsRoot: {
    backgroundColor: 'white',
    float: 'left'
  },
  tabsIndicator: {
    display: 'none'
  },
  tabRoot: {
    fontFamily: 'Montserrat',
    backgroundColor: 'rgba(33,150,243,0.08)',
    color: '#2196F3',
    fontWeight: 'bold',
    border: 0,
    textTransform: 'none',
    transition: 'background-color 0.4s',
    fontSize: 16,
    marginRight: 12,
    height: 40,
    minHeight: 40,
    borderRadius: 6,
    '&:hover': {
      backgroundColor: 'rgba(33,150,243,0.2)',
      color: '#2196F3'
    },
    '&[aria-selected="false"]': {
      color: '#2196F3',
      backgroundColor: 'white'
    }
  },
  paper: {
    width: 400,
    position: 'absolute',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    backgroundColor: theme.palette.background.paper
  }
}))

export function TabPanel (props) {
  const { children, value, index } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      style={{ padding: 20, width: '100%' }}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  )
}

export default function ModulesTabs ({siteId, userId, showCoursesTab}) {
  const classes = useStyles()
  const [value, setValue] = useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <div style={{ width: '100%' }}>
      <AppBar
        classes={{
          root: classes.appBarRoot
        }}
        position='static'
      >
        <Tabs
          value={value}
          classes={{
            root: classes.tabsRoot,
            indicator: classes.tabsIndicator
          }}
          onChange={handleChange}
        >
          
          {
            showCoursesTab && (
              <Tab
                classes={{
                  root: classes.tabRoot
                }}
                label='Cursos'
              />
            )
          }

          <Tab
            classes={{
              root: classes.tabRoot
            }}
            label='Actividad'
          />

        </Tabs>
      </AppBar>

      {
        showCoursesTab && (
          <TabPanel value={value} index={0}>
            <CoursesTab 
              userId={userId}
              siteId={siteId}
            />
          </TabPanel>
        )
      }

      <TabPanel value={value} index={!showCoursesTab ? 0 : 1}>
        <ActivitiesList
          url={`site/${siteId}/people/${userId}/activity`}
        /> 
      </TabPanel>

    </div>
  )
}
