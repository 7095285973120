import styled from 'styled-components';

export const Container = styled.div`
    flex: 1;
    padding: 15px 21px 26px 26px;
`

export const Section = styled.section`
    flex: 1;
    min-height: 80vh;
    flex-wrap: wrap;
    justify-content: flex-start;
`
export const Header = styled.div`
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
`
export const Title = styled.div`
    font-size: 38px;
    color: #004976;
    line-height: 38px;
    font-family: Montserrat;
    font-weight: 400;
`
export const CoursesCount = styled.div`
    font-family: Montserrat;
    font-size: 14px;
    margin-left: 9px;
    color: #606060;
    font-weight: 600;
`
export const Actions = styled.div`
    column-gap: 20px;
    display: flex;
    padding: 0;
`