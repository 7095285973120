import React from "react";
import ReactDOM from "react-dom";
import Amplify, { Auth } from "aws-amplify";
import Analytics from "@aws-amplify/analytics";
import { AWSIoTProvider } from "@aws-amplify/pubsub/lib/Providers";
import { trackUserIdforPinpoint } from "./utils/cognito";
import config from "./config.json";
import "bulma/css/bulma.min.css";
import "./editor/styles/index.css";
import "./index.css";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import * as serviceWorker from "./serviceWorker";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

Sentry.init({
  dsn:
    "https://9007f50adecc45398c081181428d13a3@o491338.ingest.sentry.io/5575411",
  autoSessionTracking: true,
  integrations: [new Integrations.BrowserTracing()],
  environment: window.STAGE || "localhost",
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

const conf = {
  Auth: {
    mandatorySignId: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
  },
  API: {
    endpoints: [
      {
        name: "API",
        endpoint: config.api.baseUrl,
        custom_header: async () => {
          return {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getAccessToken()
              .getJwtToken()}`,
          };
        },
      },
    ],
  },
};

Amplify.configure(conf);
Auth.configure(conf);

const analyticsConfig = {
  AWSPinpoint: {
    region: "us-east-1",
    mandatorySignIn: false,
    appId: "f0eb1731ddc74760b7b5f2a60d777257",
  },
};

Analytics.configure(analyticsConfig);

Analytics.autoTrack("session", {
  enable: true,
  provider: "AWSPinpoint",
});

Analytics.autoTrack("pageView", {
  enable: true,
  eventName: "pageView",
  type: "SPA",
  provider: "AWSPinpoint",
  getUrl: () => {
    return window.location.origin + window.location.pathname;
  },
});

trackUserIdforPinpoint();

// Amplify.Logger.LOG_LEVEL = 'DEBUG';

Amplify.addPluggable(
  new AWSIoTProvider({
    aws_pubsub_region: config.cognito.REGION,
    aws_pubsub_endpoint: `wss://${config.REACT_APP_MQTT_ID}.iot.${config.cognito.REGION}.amazonaws.com/mqtt`,
  })
);

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
