import styled from 'styled-components';

export const Container = styled.div` 
    flex: 1;
    background-color: #f6f6f6;
    display: flex;
    flex-direction: column;
    padding: 15px 21px 26px 26px;
`
export const Header = styled.div`
  display: flex;
  justify-content:space-between;  
  padding-bottom: 15px;
`
export const Content = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    column-gap: 30px
`
export const Title = styled.div`
  display: flex:
  font-family: Montserrat;
  line-height: 12px;
  font-size: 24px;
  text-align: left;
  color: #004976;
  font-weight: normal;
  letter-spacing: 0.25px;
  column-gap: 2px;
  align-items: center;
  cursor: pointer;
`