import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import { useSnackbar } from 'notistack'
import { bindActionCreators } from 'redux'
import { useParams } from 'react-router-dom'
import {
  FormControl,
  CircularProgress,
  Avatar,
  makeStyles,
  TextField,
  IconButton
} from '@material-ui/core'
import {
  Mail,
  NoteAdd,
  KeyboardBackspace,
  // ShoppingCart,
  // MonetizationOn,
  // DateRange,
  VisibilityOff,
  Visibility
} from '@material-ui/icons'
import { getUser, createUser, updateUser } from '../../../helpers/people'
import { fetchUsers, resetUser, convertUser } from '../../../redux/actions/people'
import { filterChanges, makeToast } from '../../../utils'
import { Input } from '../StyledComponents'
// import ActivityItem from '../ActivityItem'
// import VisitsChart from '../VisitsChart'
import { Alert } from '../Alert'
import Loader from '../Loader'
import Tabs from './Tabs'

const useStyles = makeStyles({
  textFieldRoot: {
    height: 56,
    borderRadius: 14,
    fontFamily: 'Montserrat',
    width: '100%',
    color: '#495057',
    fontSize: '1rem',
    display: 'block',
    borderWidth: 0,
    fontWeight: 400,
    backgroundColor: '#F7F7F7',
    backgroundClip: 'padding-box',
    transition: 'border-color .15s ease-in-out,box-shadow .15s ease-in-out',
    '& .MuiFormLabel-root': {
      fontFamily: 'Montserrat',
      '&.Mui-focused': {
        color: 'rgb(51, 55, 66)'
      }
    },
    '& .MuiInputBase-root': {
      width: '100%',
      borderRadius: 14,

      '& .MuiInputBase-input': {
        fontFamily: 'Montserrat'
      }
    },
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent !important',
      '& legend': {
        paddingRight: 10
      }
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgb(51, 55, 66) !important'
    }
  }
})

let response = {}

function UserCreate (props) {
  const { sites } = props
  const { people } = useParams()
  const { enqueueSnackbar } = useSnackbar()

  const classes = useStyles()

  const loadPeople = async () => {
    if (people) {
      response = await getUser(sites.defaultSite.id, people)
      await setData(response)
      await setEmail(response.email)
      await setFullname(response.fullname)
      await setCountry(response.country)
      await setState(response.state)
      await setCity(response.city)
      await setCreatedAt(response.createdAt)
      await setSubscribed(response.subscribed)
    }
    setPeopleReady(true)
    setLoading(false)
  }

  useEffect(() => {
    loadPeople()
  }, [])

  const [data, setData] = useState({})
  const [subscribed, setSubscribed] = useState(false)
  const [createdAt, setCreatedAt] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  const [email, setEmail] = useState('')
  const [country, setCountry] = useState('')
  const [fullname, setFullname] = useState('')
  const [peopleReady, setPeopleReady] = useState(false)
  const [loading, setLoading] = useState(true)
  const [enableEdit, setEnableEdit] = useState(false)
  const [password, setPassword] = useState('')
  const [passwordMasked, setPasswordMasked] = useState(true)

  const validate = (email, fullname ) => {
    if (!email || email.trim() === '')
      return { success: false, error: 'Email requerido' }
    if (!fullname || fullname.trim() === '')
      return { success: false, error: 'Nombre requerido' }
    return { success: true }
  }

  const handleSubmit = async ev => {
    ev.preventDefault()
    const isValid = validate(email, fullname, country, state, city)
    if (isValid.success) {
      await setLoading(true)
      try {
        const siteId = sites.defaultSite.id
        if (people) {
          const newBody = filterChanges(
            { email, fullname, city, state, country },
            data
          )
          await updateUser(siteId, people, {
            ...newBody,
            ...(password !== '' && { userPassword: password })
          })
        } else {
          await createUser(siteId, { email, fullname, city, state, country })
        }
        props.fetchUsers(siteId)
      } catch (error) {
        console.log('handleSubmit', error)
      } finally {
        await setLoading(false)
      }
    } else {
      makeToast(enqueueSnackbar, isValid.error, 'warning')
    }
  }

  const togglePasswordMasked = () => {
    setPasswordMasked(!passwordMasked)
  }

  useEffect(() => {
    if (email !== response.email) return setEnableEdit(true)
    if (fullname !== response.fullname) return setEnableEdit(true)
    if (country !== response.country) return setEnableEdit(true)
    if (state !== response.state) return setEnableEdit(true)
    if (city !== response.city) return setEnableEdit(true)
    if (password !== '') return setEnableEdit(true)
    setEnableEdit(false)
  }, [email, fullname, city, state, country, password])

  const onReset = async ev => {
    ev.preventDefault()
    Alert({
      title: '¿Estas seguro?',
      message:
        'Se enviará una contraseña temporal al usuario y una liga para restablecer su contraseña',
      onFail: () => {},
      onSuccess: async () => {
        try {
          await resetUser(sites.defaultSite.id, response.userId)
          makeToast(enqueueSnackbar, 'Se ha enviado correctamente')
        } catch (error) {
          makeToast(enqueueSnackbar, 'Ocurrio un error', 'error')
        }
      }
    })
  }

  const onConvert = async () => {
    await convertUser(sites.defaultSite.id, people)
    await setPeopleReady(false)
    await setLoading(true)
    await loadPeople()
    await setPeopleReady(true)
    await setLoading(false)
  }

  return (
    <div style={{display: 'flex', flex: 1}}>
      <div style={styles.container}>
        <div style={styles.header}>
          <div onClick={() => window.history.back()} style={styles.title}>
            <KeyboardBackspace style={{ fill: '#024976' }} />
            Contactos
          </div>
        </div>

        <div style={styles.content}>
          <div style={{ ...styles.panel, ...styles.profilePanel }}>
            {peopleReady ? (
              <>
                <div style={{ ...styles.profile, ...styles.panelSection }}>
                  <Avatar
                    style={{ width: 70, height: 70, margin: 'auto' }}
                    alt={fullname}
                    src='#'
                  />
                  <div style={styles.name}>{fullname}</div>
                  <div style={styles.email}>{email}</div>
                  <div style={styles.createdAt}>
                    {`Creado el: ${moment(createdAt).format('L')}`}
                  </div>
                  <div style={styles.subscribed}>
                    {`${
                      subscribed
                        ? `Suscrito al newsletter`
                        : `No suscrito al newsletter`
                    }`}
                  </div>
                  <div style={{ ...styles.actions, display: 'none' }}>
                    <button
                      disabled={true}
                      style={styles.actionButton}
                      onClick={() => null}
                      className='button is-primary'
                    >
                      <NoteAdd
                        style={{
                          width: 20,
                          height: 20,
                          marginRight: 5,
                          marginTop: 2
                        }}
                      />
                      Nota
                    </button>
                    <button
                      disabled={true}
                      onClick={() => (window.location.href = `mailto:${email}`)}
                      style={styles.actionButton}
                      className='button is-light'
                    >
                      <Mail
                        style={{
                          width: 20,
                          height: 20,
                          marginRight: 5,
                          marginTop: 2
                        }}
                      />
                      Correo
                    </button>
                  </div>
                  <div style={styles.actions}>
                    {
                      data.userId ? (
                        <button
                          disabled={loading}
                          onClick={ev => onReset(ev)}
                          className='button is-light'
                          style={styles.submitButton}
                        >
                          {loading ? (
                            <CircularProgress size={20} style={{ color: '#00A8D8' }}/>
                          ) : (
                            'Restablecer Contraseña'
                          )}
                        </button>
                      ) :
                        <button
                          disabled={loading}
                          onClick={ev => onConvert()}
                          className='button is-light'
                          style={styles.submitButton}
                        >
                          {loading ? (
                            <CircularProgress size={20} style={{ color: '#00A8D8' }}/>
                          ) : (
                            'Convertir a usuario'
                          )}
                        </button>
                    }
                  </div>
                </div>

                <div style={styles.separator} />

                <div style={{ ...styles.form, ...styles.panelSection }}>
                  <FormControl
                    style={{ width: '100%' }}
                    onSubmit={handleSubmit}
                  >
                    <div style={styles.field} className='field'>
                      <TextField
                        id='email'
                        type='email'
                        value={email}
                        disabled={loading}
                        label='Correo'
                        aria-describedby='emailHelp'
                        onChange={e => setEmail(e.target.value)}
                        variant='outlined'
                        classes={{ root: classes.textFieldRoot }}
                      />
                    </div>
                    <div style={styles.field} className='field'>
                      <TextField
                        type='text'
                        id='fullname'
                        value={fullname}
                        disabled={loading}
                        label='Nombre'
                        aria-describedby='fullnameHelp'
                        onChange={e => setFullname(e.target.value)}
                        variant='outlined'
                        classes={{ root: classes.textFieldRoot }}
                      />
                    </div>
                    <div style={styles.field} className='field'>
                      <TextField
                        type='text'
                        id='country'
                        value={country}
                        disabled={loading}
                        label='País'
                        aria-describedby='countryHelp'
                        onChange={e => setCountry(e.target.value)}
                        variant='outlined'
                        classes={{ root: classes.textFieldRoot }}
                      />
                    </div>
                    <div style={styles.field} className='field'>
                      <TextField
                        id='state'
                        type='text'
                        value={state}
                        disabled={loading}
                        aria-describedby='stateHelp'
                        label='Estado'
                        onChange={e => setState(e.target.value)}
                        variant='outlined'
                        classes={{ root: classes.textFieldRoot }}
                      />
                    </div>
                    <div style={styles.field} className='field'>
                      <TextField
                        id='city'
                        type='text'
                        value={city}
                        disabled={loading}
                        aria-describedby='cityHelp'
                        label='Ciudad'
                        onChange={e => setCity(e.target.value)}
                        variant='outlined'
                        classes={{ root: classes.textFieldRoot }}
                      />
                    </div>
                    {data.userId && (
                      <div
                        style={{ ...styles.field, position: 'relative' }}
                        className='field'
                      >
                        <Input
                          id='password'
                          type={passwordMasked ? 'password' : 'text'}
                          value={password}
                          autocomplete='off'
                          disabled={loading}
                          style={styles.input}
                          placeholder='Contraseña'
                          aria-describedby='passwordHelp'
                          onChange={e => setPassword(e.target.value)}
                        />
                        <IconButton
                          style={styles.passwordMaskedButton}
                          onClick={() => togglePasswordMasked()}
                        >
                          {passwordMasked ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </div>
                    )}
                    <button
                      disabled={loading || !enableEdit}
                      onClick={ev => handleSubmit(ev)}
                      className='button is-light'
                      style={styles.submitButton}
                    >
                      {loading ? <CircularProgress size={20} style={{ color: '#00A8D8' }}/> : 'Guardar'}
                    </button>
                  </FormControl>
                </div>
              </>
            ) : (
              <Loader />
            )}
          </div>

          <div style={{ ...styles.panel, ...styles.feedPanel }}>
            {
              peopleReady ? (
              <>
                <div style={{ ...styles.activity, ...styles.panelSection }}>
                  <Tabs
                    userId={people}
                    siteId={sites.defaultSite.id}
                    showCoursesTab={data.userId ? true : false}
                  />
                </div>
              </>
              ) : (
                <Loader />
              )
            }
          </div>

          {/* <div style={{...styles.panel, ...styles.statsPanel}}>

            <div style={{...styles.panelSection, paddingBottom: 15}}>
              <div style={{...styles.panelTitle}}>Estadisticas</div>

              <div style={styles.stat}>
                <div style={styles.statName}>
                  <ShoppingCart style={{fill: '#00ace7'}}/>Compras
                </div>
                <div style={styles.statValue}>3</div>
              </div>

              <div style={styles.stat}>
                <div style={styles.statName}>
                  <MonetizationOn style={{fill: '#00ace7'}}/>Gastado
                </div>
                <div style={styles.statValue}>100.00 $</div>
              </div>

              <div style={styles.stat}>
                <div style={styles.statName}>
                  <DateRange style={{fill: '#00ace7'}}/>Última Actividad
                </div>
                <div style={styles.statValue}>10/10/2020</div>
              </div>

            </div>

            <div style={{...styles.panelSection, paddingTop: 0}}>
              <div style={{...styles.panelTitle, marginBottom: 12}}>Etiquetas</div>
              <div style={styles.tagList}>
                <div style={styles.tag}>Tag 01</div>
                <div style={styles.tag}>Tag 02</div>
                <div style={styles.tag}>Tag 03</div>
              </div>
            </div>

            <div style={{...styles.panelSection}}>
              <div style={styles.panelTitle}>Visitas</div>
              <VisitsChart
                data={visits}
                title={'Visitas'}
                color='#00ace7'
              />
            </div>
          </div> */}
        </div>
      </div>
    </div>
  )
}

const styles = {
  container: {
    flex: 1,
    backgroundColor: '#f6f6f6',
    display: 'flex',
    flexDirection: 'column'
  },
  header: {
    display: 'flex',
    padding: '30px 30px 15px 30px',
    justifyContent: 'space-between'
  },
  content: {
    width: '100%',
    padding: '0px 30px 30px 30px',
    display: 'flex',
    justifyContent: 'space-between',
    columnGap: 30
  },
  title: {
    fontSize: 20,
    fontFamily: 'Montserrat',
    fontWeight: '900',
    display: 'flex',
    alignItems: 'center',
    columnGap: 2,
    color: '#024976',
    cursor: 'pointer'
  },
  panel: {
    minWidth: 330,
    backgroundColor: 'white',
    padding: '10px 0px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  },
  profilePanel: {
    flexShrink: 0,
    flexGrow: 0
  },
  feedPanel: {
    flexShrink: 1,
    flexGrow: 1
  },
  statsPanel: {
    flexShrink: 0,
    flexGrow: 0
  },
  panelSection: {
    padding: '20px 30px',
    width: '100%'
  },
  panelTitle: {
    fontFamily: 'Montserrat',
    textAlign: 'left',
    fontSize: 15,
    fontWeight: '900',
    color: '#024976',
    marginBottom: 20
  },
  separator: {
    width: '100%',
    height: 1,
    backgroundColor: 'rgb(232 232 232)'
  },
  name: {
    fontFamily: 'Montserrat',
    textAlign: 'center',
    fontSize: 15,
    fontWeight: '900',
    color: '#024976',
    marginTop: 12
  },
  email: {
    textAlign: 'center',
    fontFamily: 'Montserrat',
    fontSize: 15,
    fontWeight: '500',
    color: '#565656',
    marginTop: 3
  },
  createdAt: {
    textAlign: 'center',
    fontFamily: 'Montserrat',
    fontSize: 12,
    fontWeight: '500',
    color: '#565656',
    marginTop: 5
  },
  subscribed: {
    textAlign: 'center',
    fontFamily: 'Montserrat',
    fontSize: 12,
    fontWeight: '500',
    color: '#565656',
    marginTop: 5
  },
  actions: {
    display: 'flex',
    columnGap: 10,
    justifyContent: 'space-between',
    marginTop: 15
  },
  actionButton: {
    fontSize: 14,
    padding: '26px 36px',
    borderRadius: 8
  },
  form: {
    marginTop: 10
  },
  field: {
    textAlign: 'left',
    marginBottom: 20
  },
  label: {
    color: '#333742',
    fontSize: 14,
    marginBottom: 5,
    lineHeight: '26px'
  },
  input: {
    height: 56,
    borderRadius: 14
  },
  submitButton: {
    fontSize: 16,
    padding: '26px 0px',
    borderRadius: 8,
    width: '100%'
  },
  stat: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 18
  },
  statName: {
    fontFamily: 'Montserrat',
    color: 'rgb(177, 177, 177)',
    display: 'flex',
    columnGap: 6
  },
  statValue: {
    fontFamily: 'Montserrat',
    color: '#024976'
  },
  tagList: {
    display: 'flex',
    flexWrap: 'wrap',
    columnGap: 8,
    rowGap: 8
  },
  tag: {
    backgroundColor: 'rgb(246, 246, 246)',
    fontFamily: 'Montserrat',
    fontWeight: '600',
    fontSize: 12,
    borderRadius: 10,
    height: 26,
    display: 'flex',
    alignItems: 'center',
    padding: '0px 12px',
    color: 'rgb(177, 177, 177)'
  },
  passwordMaskedButton: {
    position: 'absolute',
    right: 8,
    bottom: 4
  }
}

const activity = [
  {
    id: 1,
    type: 'note',
    text:
      'Et sed gravida irna, a lorem, id a turpis diam mattis ullamcorper pulvinar. Libero tortor id risus diam congue vitae, libero urna. Sagittis sem congue pellentesque vitae nunc egestas aliquam diam porta.',
    createdAt: '10/10/2020'
  },
  {
    id: 2,
    type: 'purchase',
    text: 'Valdez Bruce bought the course Lorem',
    createdAt: '10/10/2020'
  },
  {
    id: 3,
    type: 'registration',
    text: 'Registered user created by',
    createdAt: '10/10/2020',
    link: {
      label: 'John Doe.',
      value: 'http://#'
    }
  },
  {
    id: 4,
    type: 'form-completition',
    text: 'Completed form',
    createdAt: '10/10/2020',
    link: {
      label: null,
      value: 'http://#'
    }
  },
  {
    id: 5,
    type: 'contact-created',
    text: 'Contact was created...',
    createdAt: '10/10/2020'
  }
]

const visits = [
  {
    label: 'Lun',
    value: 780
  },
  {
    label: 'Mar',
    value: 500
  },
  {
    label: 'Mie',
    value: 270
  },
  {
    label: 'Jue',
    value: 760
  },
  {
    label: 'Vie',
    value: 480
  },
  {
    label: 'Sab',
    value: 1000
  },
  {
    label: 'Dom',
    value: 280
  }
]

const mapStateToProps = ({ sites }) => {
  return { sites }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ fetchUsers }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(UserCreate)
